import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [0];

export const dictionary = {
		"/": [3],
		"/auth": [6],
		"/book": [7],
		"/brands": [8],
		"/call": [9],
		"/captcha": [10],
		"/dashboard": [11,[2]],
		"/dashboard/create-post": [12,[2]],
		"/dashboard/edit-post/[postId]": [13,[2]],
		"/dashboard/notifications": [14,[2]],
		"/dashboard/user-access": [15,[2]],
		"/dashboard/user-access/AddUserAccessItem": [16,[2]],
		"/dashboard/user-access/UserAccessItem": [17,[2]],
		"/dashboard/user-settings": [18,[2]],
		"/dashboard/user-settings/manage-redirects": [19,[2]],
		"/form": [20],
		"/gmap": [21],
		"/info": [22],
		"/install": [23],
		"/interests": [24],
		"/license": [26],
		"/links": [27],
		"/login": [28],
		"/l": [25],
		"/map": [29],
		"/meet": [30],
		"/music": [31],
		"/profile-image": [32],
		"/table": [33],
		"/thanks": [34],
		"/up": [35],
		"/w": [~36],
		"/[pageType]": [~4],
		"/[pageType]/[postSlug]": [5]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';